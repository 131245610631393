import React from 'react'

// Components
import Layout from 'src/layout'
import SEO from 'src/components/seo'
import Button from 'src/components/Button'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404' description='Não foi possível encontrar essa página' />
    <div className='not-found'>
      {/* eslint-disable-next-line max-len */}
      <svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'><g><path d='M472,8H40A32.03,32.03,0,0,0,8,40V472a32.03,32.03,0,0,0,32,32H472a32.03,32.03,0,0,0,32-32V40A32.03,32.03,0,0,0,472,8Zm16,464a16.021,16.021,0,0,1-16,16H40a16.021,16.021,0,0,1-16-16V72H488Zm0-416H24V40A16.021,16.021,0,0,1,40,24H472a16.021,16.021,0,0,1,16,16Z' /><circle cx='39' cy='40' r='8' /><circle cx='64' cy='40' r='8' /><circle cx='88' cy='40' r='8' /><path d='M456,424H232a8,8,0,0,0,0,16H456a8,8,0,0,0,0-16Z' /><path d='M424,448H328a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z' /><path d='M456,448h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z' /><path d='M288,376a32.036,32.036,0,0,0,32-32V200a32.036,32.036,0,0,0-32-32H224a32.036,32.036,0,0,0-32,32V344a32.036,32.036,0,0,0,32,32Zm-80-32V200a16.019,16.019,0,0,1,16-16h64a16.019,16.019,0,0,1,16,16V344a16.019,16.019,0,0,1-16,16H224A16.019,16.019,0,0,1,208,344Z' /><path d='M464,272a8,8,0,0,1-8,8H440v88a8,8,0,0,1-16,0V280H344a8,8,0,0,1-8-8V176a8,8,0,0,1,16,0v88h72V176a8,8,0,0,1,16,0v88h16A8,8,0,0,1,464,272Z' /><path d='M168,264H152V176a8,8,0,0,0-16,0v88H64V176a8,8,0,0,0-16,0v96a8,8,0,0,0,8,8h80v88a8,8,0,0,0,16,0V280h16a8,8,0,0,0,0-16Z' /><path d='M56,104H160a8,8,0,0,0,0-16H56a8,8,0,0,0,0,16Z' /><path d='M56,128H88a8,8,0,0,0,0-16H56a8,8,0,0,0,0,16Z' /><path d='M112,112a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z' /></g></svg>
      <h2><strong>404</strong> - Página não encontrada</h2>
      <p>Não foi possível encontrar a página.</p>
      <Button href='/' title='Voltar para home'>Voltar para Home</Button>
    </div>
  </Layout>
)

export default NotFoundPage
